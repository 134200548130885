import React, { useCallback, useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';
import { useHistory } from 'react-router';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import MuiLink from '@material-ui/core/Link';

import { PeakzTheme } from '../../theme/theme';
import { fetchUser } from '../../core/services/userSvc';
import {
  attachPaymentMethod,
  fetchTierById,
  fetchTiers,
  getCountryTaxRate,
  isUserCustomer,
  subscribeToAthlete,
} from '../../core/services/subscriptionsSvc';
import { formatAmount } from '../../core/helpers/currency';
import { TaxRate, Tier, User, userRoles } from '../../core/graphql/types';
import { authStore } from '../../core/stores/auth';
import CreditCardForm from '../../containers/forms/CreditCardForm';
import { appStore } from '../../core/stores/app';
import { getNextMonthName } from '../../core/helpers/misc';
import InfoIcon from '../../components/ui/InfoIcon';
import BasicPageLayout from '../../components/layouts/BasicPageLayout';
import SubmitButton from '../../components/ui/SubmitButton';
import { termsLink } from '../../config';
import { getAthleteProfileRoute } from '../../core/helpers/route';
import UserCountryDialog from '../../containers/dialogs/UserCountryDialog';
import ChangeCurrencyDialog from '../../containers/dialogs/ChangeCurrencyDialog';
import { convertToCurrency } from '../../core/services/currencySvc';
import { sendGaEvent } from '../../core/services/gaSvc';

const useStyles = makeStyles((theme: PeakzTheme) => ({
  lockIcon: {
    color: theme.palette.text.secondary,
    verticalAlign: 'middle',
    marginTop: theme.typography.pxToRem(-4),
    marginRight: theme.typography.pxToRem(4),
  },
}));

const CheckoutPage: React.FC = () => {
  const userIdParam = (useParams<{ userId?: string }>().userId || '').toLowerCase();
  const classes = useStyles();
  const stripe = useStripe();
  const location = useLocation();
  const history = useHistory();
  const [authState] = useContext(authStore);
  const [appState, appDispatch] = useContext(appStore);
  const [athlete, setAthlete] = useState<User | undefined>();
  const [tier, setTier] = useState<Tier | undefined>();
  const [taxRate, setTaxRate] = useState<TaxRate | undefined>();
  const [userIsCustomer, setUserIsCustomer] = useState<boolean | undefined>();
  const [userSubscribed, setUserSubscribed] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [countryDialogOpen, setCountryDialogOpen] = useState(false);
  const [currencyDialogOpen, setCurrencyDialogOpen] = useState(false);
  const [originalAmount, setOriginalAmount] = useState<number | undefined>();
  const [finalAmount, setFinalAmount] = useState<number | undefined>();
  const [currency, setCurrency] = useState<string | undefined>();
  //const [discount, setDiscountAmount] = useState<number | undefined>();
  const nextMonthName = getNextMonthName(2);
  const loadAthlete = useCallback(
    async (userIdentifier: string) => {
      if (authState.user) {
        const [isCustomer, user] = await Promise.all([isUserCustomer(), fetchUser(userIdentifier, '')]);

        // Only athletes are accepted
        if (!user || user.role !== userRoles.athlete) {
          history.push({
            pathname: '/athletenotfound',
            state: {
              message: 'Athlete not found',
            },
          });
        } else {
          setUserIsCustomer(isCustomer);
          setAthlete(user);
        }
      }
    },
    [authState.user],
  );
  const handleSubmitClick = async (name?: string, cardNumberElement?: any) => {
    // Pass the Element directly to other Stripe.js methods:
    // e.g. createToken - https://stripe.com/docs/js/tokens_sources/create_token?type=cardElement
    //const tokenRes = await stripe.createToken(cardNumberElement);
    //console.log(tokenRes);
    // or createPaymentMethod - https://stripe.com/docs/js/payment_intents/create_payment_method
    if (stripe && athlete && tier && appState.userCurrencyConfig) {
      appDispatch({ type: 'showBackdrop', backdropTxt: 'Processing your order...' });
      setSubmitting(true);

      try {
        let canSubscribeToAthlete = userIsCustomer;

        // If the user is not a customer yet, create it (done automatically by the backend) and attach the payment method
        if (!userIsCustomer) {
          const { paymentMethod, error } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardNumberElement,
            billing_details: {
              name: name,
            },
          });

          if (error) {
            console.log(error);
            appDispatch({
              type: 'showToast',
              toastTxt: "Your payment method couldn't be created",
              toastSeverity: 'error',
            });
          } else if (paymentMethod) {
            // It will create a customer, attach the payment method and set it as default
            await attachPaymentMethod(paymentMethod.id);
            canSubscribeToAthlete = true;
          }
        }

        // Subscribe to the Athlete
        if (canSubscribeToAthlete) {
          await subscribeToAthlete(
            athlete.id,
            tier.id,
            appState.userCurrencyConfig.currencyCode,
            taxRate ? taxRate.id : undefined,
          );
          setUserSubscribed(true);
          history.push({
            pathname: getAthleteProfileRoute(athlete),
            state: { checkoutSuccess: true },
          });
        }
      } catch (err: any) {
        appDispatch({
          type: 'showToast',
          toastTxt: err.errors[0].message,
          toastSeverity: 'error',
        });
      } finally {
        setSubmitting(false);
        appDispatch({ type: 'hideBackdrop' });
      }
    }
  };
  const handleCountrySubmitted = () => {
    setCountryDialogOpen(false);
  };
  const handleCurrencyChangeSubmit = async () => {
    setCurrencyDialogOpen(false);
  };
  const handleCurrencyChangeCancel = () => {
    setCurrencyDialogOpen(false);
  };
  const handleChangeCurrencyClick = () => {
    setCurrencyDialogOpen(true);
  };

  useEffect(() => {
    loadAthlete(userIdParam);

    return () => {
      let id: any;
      if (appState && appState.user) {
        id = appState?.user?.givenName + appState?.user?.familyName || appState.user?.email;
      }

      if (!userSubscribed) {
        sendGaEvent(
          'check-out-leaver',
          'leave',
          `user ${id} left the checkout for athlete ${
            athlete?.givenName + ' | ' + athlete?.familyName + ' | ' + athlete?.email
          }`,
        );
      }
    };
  }, [userIdParam, loadAthlete]);

  useEffect(() => {
    if (appState.user) {
      if (!appState.user.country) {
        setCountryDialogOpen(true);
      } else {
        (async function () {
          if (appState.user?.country) {
            const res = await getCountryTaxRate(appState.user.country);

            if (res) {
              setTaxRate(res);
            }
          }
        })();
      }
    }
  }, [appState.user]);

  useEffect(() => {
    (async function () {
      const searchParams = new URLSearchParams(window.location.search);
      const tid = searchParams.get('tid');

      if (tid && !tier) {
        setTier(await fetchTierById(tid));
      } else if (athlete) {
        const athleteTiers = await fetchTiers(athlete.id);

        setTier(athleteTiers[0]);
      }
    })();
  }, [athlete, location.search]);

  useEffect(() => {
    if (appState.userCurrencyConfig && appState.currenciesConfig && tier) {
      const theCurrency = appState.userCurrencyConfig.userDefined
        ? appState.userCurrencyConfig.currencyCode
        : tier.currency;
      const orgAmount = convertToCurrency(appState.currenciesConfig, tier.unitAmount, tier.currency, theCurrency);

      setOriginalAmount(orgAmount);
      setFinalAmount(orgAmount);

      setCurrency(theCurrency);
    }
  }, [appState.userCurrencyConfig, appState.currenciesConfig, tier]);

  return (
    <BasicPageLayout
      titleChildren={
        <>
          {athlete && (
            <Typography variant="h6" align="center">
              Complete your monthly payment to <Link to={getAthleteProfileRoute(athlete)}>{athlete?.givenName}</Link>
            </Typography>
          )}
        </>
      }
    >
      {/* Content */}
      {authState.user && athlete && (
        <Grid container spacing={2}>
          {/* Payment Details */}
          <Grid item xs={12} md={7}>
            <Paper variant="outlined">
              <Box p={3}>
                <Grid container spacing={4}>
                  <Grid item xs={3}>
                    <Typography variant="body1">
                      <b>
                        Payment <br />
                        details
                      </b>
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Paper variant="outlined" square>
                      <Box p={2}>
                        <Typography variant="body1">
                          <b>CARD</b>
                        </Typography>
                        <Box mt={3}>
                          {userIsCustomer && (
                            <Box>
                              <Typography variant="body1">
                                It seems you are already a fan. You can complete your subscription with{' '}
                                <b>one single click</b>!
                              </Typography>
                              <Box mt={3}>
                                <SubmitButton fullWidth submitting={submitting} onClick={() => handleSubmitClick()}>
                                  {`Subscribe to ${athlete?.givenName}'s posts`}
                                </SubmitButton>
                              </Box>
                            </Box>
                          )}
                          {!userIsCustomer && (
                            <CreditCardForm
                              submitting={submitting}
                              submitButtonText="Pay with card"
                              onSubmitClick={handleSubmitClick}
                            />
                          )}
                        </Box>
                      </Box>
                    </Paper>
                    <Box mt={1}>
                      <Grid container alignItems="center" justifyContent="center">
                        <Grid item>
                          <LockOutlinedIcon className={classes.lockIcon} fontSize="small" />
                        </Grid>
                        <Grid item>
                          <Typography variant="body1" color="textSecondary" align="center">
                            Secure checkout
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
          {/* Summary */}
          <Grid item xs={12} md={5}>
            <Paper variant="outlined">
              {/* Product Info */}
              <Box p={3}>
                <Typography variant="body1">
                  <b>SUMMARY</b>
                </Typography>
                {/* Product */}
                <Box mt={3}>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Typography variant="body1">
                        <b>{tier?.name}</b>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">
                        <span style={{ fontWeight: 'bold' }}>
                          {originalAmount && currency ? formatAmount(originalAmount, currency) : ''}
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                {/* Discount */}
                <Box mt={0}>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Typography variant="body1" style={{ color: 'red' }}>{`First month for free`}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" style={{ color: 'red' }}>
                        <span style={{ fontWeight: 'bold' }}>{currency ? formatAmount(0, currency) : ''}</span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                {taxRate && (
                  <Box mt={2}>
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <Typography variant="body1">
                          <b>{`${taxRate.displayName} (${taxRate.country})`}</b>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">
                          <b>
                            {finalAmount && currency
                              ? formatAmount((finalAmount * taxRate.percentage) / 100, currency)
                              : ''}
                          </b>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {/* Divider */}
                <Box mt={3}>
                  <Divider />
                </Box>
                <Box mt={3}>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Typography variant="body1">
                        <b>Today’s charge</b>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">
                        <b>{finalAmount && originalAmount && currency ? `${formatAmount(0, currency)}*` : ''}</b>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                {/* Divider */}
                <Box mt={3}>
                  <Divider />
                </Box>
                {/* Currency */}
                <Box mt={3}>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Typography variant="body1">
                        <b>Currency</b>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">
                        <b>{currency ? currency.toUpperCase() : ''}</b>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                {/* https://support.patreon.com/hc/en-us/articles/360048258772-Changing-your-payout-currency */}
                {/* https://support.patreon.com/hc/en-us/articles/360044469871--How-tiers-are-converted-into-other-currencies */}
                <Box mt={1}>
                  <Typography
                    variant="body1"
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={handleChangeCurrencyClick}
                  >
                    <MuiLink>Change</MuiLink>
                  </Typography>
                </Box>
              </Box>
              {/* Subscription Info */}
              <Divider />
              <Box p={3}>
                <Typography variant="body1">
                  <b>Renews automatically with normal subscription price on {nextMonthName} 1 at 12am WET Time</b>
                </Typography>
                <Box mt={2}>
                  <Typography variant="body1" gutterBottom>
                    You can cancel auto-renew at any time or edit your payment. By making this payment, you agree to{' '}
                    <a href={termsLink} target="_blank" rel="noreferrer">
                      Peakz's Terms of Use
                    </a>
                    .
                  </Typography>
                </Box>
                <Box mt={2}>
                  <Typography variant="body2" gutterBottom>
                    * Depending on your location your bank might charge an additional foreign transaction fee for your
                    membership to this Athlete.
                    <InfoIcon
                      text="Peakz uses payment processors based around the globe. Some payments are processed outside of
                            your home country and when that happens some card issuers may charge a foreign transaction
                            fee. Not all issuers charge a fee. Please contact your credit/debit card issuer for more
                            information. If your card issuer adds a fee for foreign transactions consider using a card
                            that doesn’t charge this fee."
                    />
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      )}
      {!athlete && (
        <Box display="flex" justifyContent="center" width={1}>
          <CircularProgress size={36} />
        </Box>
      )}

      {/* Country Dialog */}
      {countryDialogOpen && <UserCountryDialog open={countryDialogOpen} onSubmitted={handleCountrySubmitted} />}

      {/* Currency Dialog */}
      {currencyDialogOpen && currency && (
        <ChangeCurrencyDialog
          open={currencyDialogOpen}
          initCurrency={currency}
          onSubmitted={handleCurrencyChangeSubmit}
          onCancel={handleCurrencyChangeCancel}
        />
      )}
    </BasicPageLayout>
  );
};

export default CheckoutPage;
