/* Showed in postType/VideoView and postType/PodcastView
   if the user is not subscribed to see the content */

import React, { useState, useEffect, useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Chip from '@material-ui/core/Chip';

import { Post } from '../../core/graphql/types';
import { appStore } from '../../core/stores/app';
import { convertToCurrency } from '../../core/services/currencySvc';
import { DiscountType } from '../../API';

interface Props {
  post: Post;
  classes: any;
  onWatchPreviewClicked?: () => void;
}

const PostLockOverlay: React.FC<Props> = ({ post, classes, onWatchPreviewClicked }: Props) => {
  const [appState] = useContext(appStore);
  const [originalAmount, setOriginalAmount] = useState<number | undefined>();
  const [finalAmount, setFinalAmount] = useState<number | undefined>();
  const [currency, setCurrency] = useState<string | undefined>();
  const handleWatchPreviewClick = (evt: any) => {
    if (onWatchPreviewClicked) {
      evt.stopPropagation();
      onWatchPreviewClicked();
    }
  };

  useEffect(() => {
    if (appState.userCurrencyConfig && appState.currenciesConfig && post && post.tier) {
      const orgAmount = convertToCurrency(
        appState.currenciesConfig,
        post.tier.unitAmount,
        post.tier.currency,
        appState.userCurrencyConfig.currencyCode,
      );

      setOriginalAmount(orgAmount);
      if (post.tier.discountType === DiscountType.percent && post.tier.discount) {
        setFinalAmount(orgAmount - (orgAmount * post.tier.discount) / 100);
      } else {
        setFinalAmount(orgAmount);
      }

      setCurrency(appState.userCurrencyConfig.currencyCode);
    }
  }, [appState.userCurrencyConfig, appState.currenciesConfig, post]);

  return (
    <Grid
      className={classes.postLockOverlay}
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <Grid item>
        <LockOutlinedIcon fontSize="large" />
      </Grid>
      <Grid container item justifyContent="center">
        <Box display="flex" justifyContent="center">
          <Typography className={classes.postLockOverlayTitle} gutterBottom align="center">
            Unlock this post by becoming a fan
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        {finalAmount && originalAmount && currency && (
          <Chip
            className={classes.postLockOverlayButton}
            color="primary"
            label={<div>First month for free! Join now!</div>}
          />
        )}
      </Grid>
      {post.vodMetadata?.hasPreview && onWatchPreviewClicked && (
        <Grid item>
          <Box mt={1}>
            <Button variant="outlined" className={classes.previewButton} onClick={handleWatchPreviewClick}>
              Watch preview
            </Button>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default PostLockOverlay;
